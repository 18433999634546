<template>
  <div class="clearfix home">
    <el-col :span="24" class="header">
      <div class="subHeader">
        <el-col :span="5" class="logo">
          <div class="tools fl" @click.prevent="collapse">
            <el-icon
              :class="collapsed ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
            ></el-icon>
          </div>
          <img class="fl" src="./assets/csc_logo.png" />
        </el-col>
        <!--  顶部菜单栏-->
        <el-col :span="12">
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            text-color="#333"
            active-text-color="#e60012"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item index="/Home">首页</el-menu-item>
            <el-menu-item index="/resume" v-if="hasMenuPage('resume')"
              >人才
            </el-menu-item>
            <el-menu-item
              index="/project"
              id="project"
              v-if="hasMenuPage('progream')"
              >项目</el-menu-item
            >
            <el-menu-item index="/customer" v-if="hasMenuPage('customer')"
              >客户</el-menu-item
            >
            <el-menu-item index="/Pipeline" v-if="hasMenuPage('Pipeline')"
              >Pipeline</el-menu-item
            >
            <el-submenu
              index="22222"
              v-if="hasMenuPage('contract')"
              popper-class="subMenus"
            >
              <template slot="title" id="contracts">合同</template>
              <el-menu-item
                index="/contracts"
                v-if="hasMenuPage('contract:index')"
                id="contractsPage"
                >合同管理</el-menu-item
              >
              <el-menu-item
                index="/contractApproval"
                v-if="hasMenuPage('contract:approval')"
                id="contractApprovalPage"
                >合同审批</el-menu-item
              >
            </el-submenu>
            <el-submenu
              index="333333"
              v-if="hasMenuPage('record')"
              popper-class="subMenus"
            >
              <template slot="title">报表</template>
              <el-menu-item
                index="/report/Kpi"
                v-if="hasMenuPage('record:KpiData')"
                >KPI</el-menu-item
              >
              <el-menu-item
                index="/report/teamKpi"
                v-if="hasMenuPage('record:teamKpi')"
                >团队KPI</el-menu-item
              >
              <el-menu-item
                index="/report/Pool"
                v-if="hasMenuPage('record:Pool')"
                >业绩池</el-menu-item
              >
              <el-menu-item
                index="/report/PerformanceTable"
                v-if="hasMenuPage('record:PerformanceTable')"
                >业绩报表</el-menu-item
              >
              <el-menu-item
                index="/report/performance"
                v-if="hasMenuPage('record:performance')"
                >业绩分析</el-menu-item
              >
              <el-menu-item
                index="/report/overdueCollection"
                v-if="hasMenuPage('record:overdueCollection')"
                >逾期回款报表</el-menu-item
              >
              <el-menu-item
                index="/report/realtime"
                v-if="hasMenuPage('record:realtime')"
                >实时监控</el-menu-item
              >
              <el-menu-item
                index="/report/performanceConfig"
                v-if="hasMenuPage('record:performanceConfig')"
                >业绩目标配置</el-menu-item
              >
              <el-menu-item
                index="/report/emailStatistics"
                v-if="hasMenuPage('record:emailStatistics')"
                >邮件统计</el-menu-item
              >
              <el-menu-item
                index="/report/bonusCalculationSsc"
                v-if="hasMenuPage('record:bonusCalculationSsc')"
                >奖金测算_SSC</el-menu-item
              >
              <el-menu-item
                index="/report/accrualCalculationSsc"
                v-if="hasMenuPage('record:accrualCalculationSsc')"
                >应发计提测算_SSC</el-menu-item
              >
              <el-menu-item
                index="/report/accrualReport"
                v-if="hasMenuPage('record:accrualReport')"
                >计提报表</el-menu-item
              >
              <el-menu-item
                index="/report/bonusReport"
                v-if="hasMenuPage('record:bonusReport')"
                >奖金报表</el-menu-item
              >
              <el-menu-item
                index="/report/bonusChargeSsc"
                v-if="hasMenuPage('record:bonusChargeSsc')"
                >负责人确认奖金_SSC</el-menu-item
              >
            </el-submenu>
            <el-submenu
              index="444444"
              v-if="hasMenuPage('finance')"
              popper-class="subMenus"
            >
              <template slot="title">财务</template>
              <el-menu-item
                index="/financeManagement"
                v-if="hasMenuPage('finance:index')"
                >财务管理</el-menu-item
              >
              <el-menu-item
                index="/financeEnquiries"
                v-if="hasMenuPage('finance:financeHandle')"
                >财务查询</el-menu-item
              >
            </el-submenu>
            <el-submenu
              index="5555555"
              v-if="hasMenuPage('setting')"
              popper-class="subMenus"
            >
              <template slot="title">设置</template>
              <el-menu-item
                index="/setting/addressbook"
                v-if="hasMenuPage('setting:user')"
                >通讯录</el-menu-item
              >
              <el-menu-item
                index="/setting/roles"
                v-if="hasMenuPage('setting:role')"
                >角色管理</el-menu-item
              >
              <el-menu-item
                index="/setting/customfield"
                v-if="hasMenuPage('setting:customfield')"
                >自定义字段</el-menu-item
              >
              <el-menu-item
                index="/setting/wordtemp"
                v-if="hasMenuPage('setting:wordtemp')"
                >模板文件</el-menu-item
              >
              <el-menu-item
                index="/setting/marktemp"
                v-if="hasMenuPage('setting:marktemp')"
                >备注管理</el-menu-item
              >
              <el-menu-item
                index="/setting/defaultkpi"
                v-if="hasMenuPage('setting:defaultkpi')"
                >默认业绩分配方案</el-menu-item
              >
              <el-menu-item
                index="/setting/signcompany"
                v-if="hasMenuPage('setting:signcompany')"
                >签约公司配置</el-menu-item
              >
              <el-menu-item
                index="/setting/workflow"
                v-if="hasMenuPage('setting:workflow')"
                >审批流配置</el-menu-item
              >
              <el-menu-item
                index="/setting/specialCom"
                v-if="hasMenuPage('setting:specialCom')"
                >特殊公司</el-menu-item
              >
              <el-menu-item
                index="/setting/resumeConfig"
                v-if="hasMenuPage('setting:resumeconfig')"
                >人才字段匹配</el-menu-item
              >
              <el-menu-item
                index="/setting/quartzJob"
                v-if="hasMenuPage('setting:quartzJob')"
                >定时任务管理</el-menu-item
              >
              <el-menu-item
                index="/setting/xbWorkflow"
                v-if="hasMenuPage('setting:xbWorkflow')"
                >喜报审批流配置</el-menu-item
              >
              <el-menu-item
                index="/setting/systemAnnouncement"
                v-if="hasMenuPage('setting:systemAnnouncement')"
                >系统公告</el-menu-item
              >
              <el-menu-item
                index="/setting/labelLibrary"
                v-if="hasMenuPage('setting:labelLibrary')"
                >标签库</el-menu-item
              >
              <el-menu-item
                index="/setting/customLabelLibrary"
                v-if="hasMenuPage('setting:customLabelLibrary')"
                >个性化标签库</el-menu-item
              >
              <el-menu-item
                index="/setting/thesaurus"
                v-if="hasMenuPage('setting:thesaurus')"
                >同义词库</el-menu-item
              >
              <el-menu-item
                index="/setting/bonusModel"
                v-if="hasMenuPage('setting:bonusModel')"
                >奖金计算模型</el-menu-item
              >
              <el-menu-item
                index="/setting/consultantBonus"
                v-if="hasMenuPage('setting:consultantBonus')"
                >顾问奖金设置</el-menu-item
              >
            </el-submenu>
            <el-submenu
              index="666666"
              v-if="hasMenuPage('tools')"
              popper-class="subMenus"
            >
              <template slot="title">工具</template>
              <el-menu-item
                index="/calendar"
                v-if="hasMenuPage('tools:calendar')"
                >日程</el-menu-item
              >
              <el-menu-item
                index="/tools/happyNewsApproval"
                v-if="hasMenuPage('tools:happyNewsApproval')"
                >喜报审批</el-menu-item
              >
              <el-menu-item
                index="/tools/emailSet"
                v-if="hasMenuPage('tools:emailSet')"
                >邮件设置</el-menu-item
              >
              <el-menu-item
                index="/tools/exportingRecords"
                v-if="hasMenuPage('tools:exportingRecords')"
                >导出记录</el-menu-item
              >
              <el-menu-item
                index="/tools/noContractCommentApproval"
                v-if="hasMenuPage('tools:noContractCommentApproval')"
                >无合同开票审批</el-menu-item
              >
            </el-submenu>
          </el-menu>
        </el-col>
        <el-col :span="6" class="userinfo">
          <div class="iconLength" id="iconLength">
            <div
              class="icons"
              v-if="hasMenuPage('pendingApproval')"
              @click="goPendingApproval"
            >
              <el-badge
                :value="approvalcount"
                :hidden="approvalcount > 0 ? false : true"
                class="itemMsg"
                :max="99"
              >
                <el-button size="mini" title="待审批">待审批</el-button>
              </el-badge>
            </div>
            <div class="icons">
              <el-popover
                placement="bottom"
                width="180"
                popper-class="nopadding"
                trigger="hover"
                :open-delay="1000"
                @show="setStatisticsMessageShow"
              >
                <ul class="msgList">
                  <li
                    v-for="(item, index) in messageList"
                    :key="index"
                    @click="getstatisticsMessageCount(item)"
                  >
                    <router-link
                      v-on:listenToChildEvent="decrement"
                      :to="{
                        path: '/message/finance',
                        query: { index, index },
                      }"
                    >
                      {{ item.label }}
                      <span>{{ item.count }}</span>
                    </router-link>
                  </li>
                  <p class="allRead" @click="readAll">全部已读</p>
                </ul>
                <span slot="reference">
                  <el-badge
                    :value="count"
                    :hidden="count > 0 ? false : true"
                    class="itemMsg"
                    :max="99"
                  >
                    <i class="iconfont icon-top_nav_xx"></i>
                  </el-badge>
                </span>
              </el-popover>
            </div>
            <div class="icons" style="margin-left: 5px">
              <el-popover
                placement="bottom"
                width="180"
                popper-class="nopadding"
                trigger="hover"
                v-model="showFeedbackVis"
                :open-delay="1000"
              >
                <ul class="msgList" style="text-align: left">
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://doc.weixin.qq.com/forms/AEcAvgcVAAYAKYA8wZHAAoLsJYU9DRQgf"
                    >
                      创建查重问题
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://doc.weixin.qq.com/sheet/e3_AKYA8wZHAAoJdeI283KR70AIKL1sm?scode=AEcAvgcVAAY5oP2qwqAKYA8wZHAAo"
                    >
                      查看查重问题
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://doc.weixin.qq.com/forms/AEcAvgcVAAYAKYA8wZHAAoLsJYU9DRQgf"
                    >
                      创建新需求/反馈
                    </a>
                  </li>
                  <li style="margin-bottom: 10px">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://doc.weixin.qq.com/sheet/e3_AKYA8wZHAAoJdeI283KR70AIKL1sm?scode=AEcAvgcVAAY5oP2qwqAKYA8wZHAAo"
                    >
                      查看新需求/反馈
                    </a>
                  </li>
                </ul>
                <span slot="reference">
                  <span style="color: rgb(230, 0, 18); font-weight: bold; cursor: pointer;"  @click="showFeedback">反馈</span>
                </span>
              </el-popover>
            </div>
            <div class="icons">
              <el-popover
                placement="bottom"
                trigger="hover"
                v-model="showQPopVis"
                :open-delay="1000"
              >
                <ul class="quickContral">
                  <li @click="addPersonSelect">
                    <i class="iconfont icon-lnav_wdwj iconL"></i>新增人才
                  </li>
                  <li @click="uponPersonSelect">
                    <i class="iconfont icon-kj_scrc iconL"></i>上传人才
                  </li>
                  <li @click="uploadResumeLiost">
                    <i class="iconfont icon-kj_list iconL"></i>上传人才list
                  </li>
                  <li @click="addCustomer">
                    <i class="iconfont icon-kj_xzkh iconL"></i>新增客户
                  </li>
                  <li @click="addProject">
                    <i class="iconfont icon-kj_xzxm iconL"></i>新增项目
                  </li>
                  <li @click="addContracts">
                    <i class="iconfont icon-kj_xzrc iconL"></i>新增合同
                  </li>
                  <li @click="addCalendar">
                    <i class="iconfont icon-rc_gd_xzrc iconL"></i>新增日程
                  </li>
                  <li @click="dowmExport">
                    <i class="iconfont icon-kj_cj"></i>下载插件
                  </li>
                </ul>
                <i
                  slot="reference"
                  @click="showQPop"
                  class="addIcon iconfont icon-top_nav_tj"
                ></i>
              </el-popover>
            </div>

            <div class="userinfo-inner">
              <el-popover
                placement="bottom"
                popper-class="nopadding"
                width="250"
                trigger="hover"
                :open-delay="1000"
              >
                <div class="userPop">
                  <ul>
                    <li>
                      账户：<span class="zh text-overflows">{{
                        sysUserInfo.userName
                      }}</span>
                    </li>
                    <li>用户名：{{ nickName }}</li>
                    <li class="roles">
                      角色：
                      <span v-for="(item, _index) in roles" :key="_index">{{
                        item
                      }}</span>
                    </li>
                    <li>
                      组织框架：
                      <p
                        class="fr"
                        v-for="(item, _index) in departments"
                        :key="_index"
                      >
                        {{ item }}
                      </p>
                    </li>
                    <li v-if="mobile != 'null' && mobile">
                      手机号码：{{ mobile }}
                    </li>
                  </ul>
                  <div class="user-control">
                    <p class="account">
                      <router-link to="/account">
                        <span class="rline">
                          <i class="iconfont iconzhanghushezhi"></i>账户设置
                        </span>
                      </router-link>
                    </p>
                    <p class="account">
                      <span class="icon" @click="logout" id="logout">
                        <i class="iconfont icontuichutubiao"></i>退出登录
                      </span>
                    </p>
                  </div>
                </div>

                <div slot="reference" class="clearfix" style="height: 40px">
                  <img
                    class="fl"
                    :src="this.avatar"
                    :title="nickName"
                    id="avatar"
                  />
                </div>
              </el-popover>
            </div>
          </div>
          <div class="coverBorder"></div>
          <div class="coverBorder1"></div>
        </el-col>
      </div>
    </el-col>
    <!--  中间可视部分-->
    <div class="content">
      <router-view></router-view>
    </div>
    <div
      v-drag
      draggable="false"
      class="baberage-happynews"
      v-if="ListData.length > 0 || isShowHpayyIcon"
    >
      <div class="waitRead">{{ ListData.length }}</div>
      <img
        style="cursor: pointer; width: 200px"
        src="./assets/happyNewsImg.gif"
        alt=""
      />
    </div>
    <div v-if="barrageIsShow" class="baberrageWrape">
      <div class="happyForm" v-if="happyNewsForm.candidateName">
        <div class="noOver" v-if="isOver10 >= 100000">
          <img class="noOverbg" src="./assets/noOver10.png" alt="" />
          <img
            v-if="happyNewsForm.candidateName"
            :class="['plane', { animate__plane: happyNewsForm.candidateName }]"
            src="./assets/plane.png"
            alt="plane"
          />
        </div>
        <div class="Over" v-if="isOver10 < 100000">
          <img src="./assets/over10.png" alt="" />
          <img
            v-if="happyNewsForm.candidateName"
            :class="[
              'goldMedal',
              { animate__backInDown: happyNewsForm.candidateName },
            ]"
            src="./assets/goldMedal.png"
            alt="奖牌"
          />
          <img v-if="isShowStar" class="star" src="./assets/star.png" alt="" />
        </div>
        <img
          style="position: absolute; right: -55px; top: 195px; cursor: pointer"
          v-if="ListData.length > 0"
          @click="baberrageShow"
          src="./assets/next.png"
          alt=""
        />
        <div class="sevMoney">
          <p>
            服务费用：<span>{{
              happyNewsForm.money.isShow ? happyNewsForm.money.name : "暂未公开"
            }}</span>
          </p>
        </div>
        <i class="el-icon-circle-close" @click="closeHappyDialog"></i>
        <el-form
          :model="happyNewsForm"
          @submit.native.prevent
          style="padding: 0 30px"
        >
          <el-row>
            <p class="catx">恭喜下方顾问:</p>
            <p class="defaultTitle">
              成功offer
              <span class="value">{{
                happyNewsForm.companyName.isShow
                  ? happyNewsForm.companyName.name
                  : "****"
              }}</span>
              的
              <span class="value">{{
                happyNewsForm.position.isShow
                  ? happyNewsForm.position.name
                  : "****"
              }}</span
              >，候选人
              <span class="value">{{
                happyNewsForm.candidateName.isShow
                  ? happyNewsForm.candidateName.name
                  : "****"
              }}</span>
              于
              <span class="value">{{
                happyNewsForm.offerTime.isShow
                  ? happyNewsForm.offerTime.name
                  : "****"
              }}</span>
              收到offer，预计
              <span class="value">{{
                happyNewsForm.entryTime.isShow
                  ? happyNewsForm.entryTime.name
                  : "****"
              }}</span>
              入职。
            </p>
            <div class="conten">
              <div
                class="userList"
                :style="{
                  'justify-content':
                    Number(
                      Number(shortItems.length) + Number(longItems.length)
                    ) > 7
                      ? 'space-between'
                      : 'center',
                }"
              >
                <div
                  :key="item.index"
                  v-for="item in shortItems"
                  class="userItem"
                >
                  <img
                    :src="item.avatar ? baseUrl + item.avatar : userAvator"
                    alt=""
                  />
                  <span class="nameStyle name" :title="item.userName">{{
                    item.userName
                  }}</span>
                  <span class="nameStyle area" :title="item.area"
                    >({{ item.area ? item.area : "-" }})</span
                  >
                </div>
                <div
                  :key="item.index"
                  v-for="item in longItems"
                  class="userItem"
                >
                  <img
                    :title="item.userName"
                    :src="item.avatar ? baseUrl + item.avatar : userAvator"
                    alt=""
                  />
                  <span class="nameStyle name" :title="item.userName">{{
                    item.userName
                  }}</span>
                  <span class="nameStyle area" :title="item.area"
                    >({{ item.area ? item.area : "-" }})</span
                  >
                </div>
              </div>
            </div>
            <div class="quickBtn" v-if="ListData.length > 0">
              <el-button size="mini" @click="quickSeeAll"
                >快速浏览全部
              </el-button>
            </div>
          </el-row>
        </el-form>
      </div>
    </div>
    <div v-if="customizationMsgIsShow" class="baberrageWrape">
      <div class="happyForm" style="padding-bottom: 0">
        <img
          class="noOverbg"
          src="./assets/Customizationbg.png"
          alt=""
          style="display: block; border-radius: 10px"
        />
        <div class="customizationContent">
          <i class="el-icon-circle-close" @click="closeCustomizationMsg"></i>
          <div class="details">
            <p class="title">{{ customizationMsg.title }}</p>
            <div
              v-html="customizationMsg.content"
              style="line-height: 22px"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <el-drawer
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      append-to-body
      :modal="false"
      direction="ttb"
      custom-class="drawerClass"
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-rc_bt"></i>上传人才
      </div>
      <div class="drawerCon">
        <upResume
          v-model="enableSub"
          @closeDia="closeDrawer"
          ref="uploadRResume"
          v-if="dialogVisible"
        ></upResume>

        <div class="drawerCon_footer fixedFoot">
          <el-button
            type="default"
            plain
            size="mini"
            @click="handleClose('点击取消按钮关闭弹框')"
            >取消</el-button
          >
          <el-button
            :disabled="!enableSub"
            type="primary"
            size="mini"
            style="margin-right: 15px"
            :loading="saveloading"
            @click="saveInfos"
            >保存</el-button
          >
        </div>
      </div>
    </el-drawer>
    <el-dialog
      title="反馈入职结果"
      :visible.sync="dialogVisibleBack"
      width="600px"
      destroy-on-close
      :before-close="dialogVisibleBackClose"
    >
      <el-form
        v-if="dialogVisibleBack"
        @submit.native.prevent
        :model="enrtyForm"
        label-width="150px"
        ref="enrtyForm"
      >
        <el-form-item label="结果：" class="is-required" prop="result">
          <el-select
            v-model="enrtyForm.result"
            @change="changeResult($event)"
            style="width: 250px"
          >
            <el-option value="成功" label="成功入职"></el-option>
            <el-option value="拒绝" label="候选人拒绝"></el-option>
            <el-option value="延期" label="延期入职"></el-option>
          </el-select>
        </el-form-item>
        <div v-if="enrtyForm.result == '成功'">
          <el-form-item
            label="入职时间："
            :rules="[
              { required: true, message: '请选择入职时间', trigger: 'change' },
            ]"
            prop="time"
          >
            <el-date-picker
              v-model="enrtyForm.time"
              type="date"
              :disabled="disabled"
              placeholder="选择日期"
              style="width: 250px"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            label="试用期结束："
            :rules="[
              { required: true, message: '请选择时间', trigger: 'change' },
            ]"
            prop="endTimeOfTrial"
            style="margin-bottom: 0"
          >
            <el-date-picker
              v-model="enrtyForm.endTimeOfTrial"
              style="width: 250px"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item style="margin-bottom: 0">
            <el-checkbox v-model="enrtyForm.addToSchedule"
              >将试用期结束时间添加到日程提醒</el-checkbox
            >
          </el-form-item>
          <el-form-item
            label="约定首次开票时间："
            :rules="[
              { required: true, message: '请选择时间', trigger: 'change' },
            ]"
            prop="conventionFirstMakeInvoiceDate"
            style="margin-bottom: 5px"
          >
            <el-date-picker
              v-model="enrtyForm.conventionFirstMakeInvoiceDate"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              style="width: 360px"
            ></el-date-picker>
          </el-form-item>
          <el-form-item style="margin-bottom: 0">
            <el-checkbox
              v-model="enrtyForm.firstMakeInvoiceAddToSchedule"
              disabled
              >将自动添加到日程提醒</el-checkbox
            >
          </el-form-item>
          <el-form-item label="是否设置项目成功：">
            <el-checkbox v-model="enrtyForm.projectFinished">是</el-checkbox>
          </el-form-item>
        </div>
        <div v-if="enrtyForm.result == '拒绝'" style="padding: 0 70px">
          <el-form
            @submit.native.prevent
            label-position="top"
            v-if="CommentWay.columns && CommentWay.columns.length > 0"
          >
            <el-row :gutter="24">
              <el-col
                :span="24"
                v-for="(item, index) in CommentWay.columns"
                :key="item.id"
              >
                <el-form-item
                  :label="index + 1 + '.' + item.title"
                  :required="item.required"
                  class="selectLabel"
                >
                  <div
                    v-if="item.type == '单选型' && item.selectType == '单选'"
                  >
                    <el-radio-group v-model="item.answer">
                      <p v-for="(i, _index) in item.options" :key="_index">
                        <el-radio
                          :label="i"
                          @click.native.prevent="clickItem(i, index)"
                          >{{ i }}</el-radio
                        >
                      </p>
                    </el-radio-group>
                  </div>
                  <div
                    v-if="item.type == '单选型' && item.selectType == '下拉'"
                  >
                    <el-select
                      v-model="item.answer"
                      clearable
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="(i, _index) in item.options"
                        :key="_index"
                        :label="i"
                        :value="i"
                      ></el-option>
                    </el-select>
                  </div>
                  <div v-if="item.type == '多选型'">
                    <el-checkbox-group v-model="item.answer1">
                      <p v-for="(i, _index) in item.options" :key="_index">
                        <el-checkbox :label="i">{{ i }}</el-checkbox>
                      </p>
                    </el-checkbox-group>
                  </div>
                  <div v-if="item.type == '文本描述型'">
                    <el-input
                      v-model="item.answer"
                      placeholder="请输入内容"
                      type="textarea"
                      :rows="3"
                    ></el-input>
                  </div>
                  <div v-if="item.type == '日期型'">
                    <el-date-picker
                      prefix-icon="el-icon-date"
                      v-model="item.answer"
                      type="date"
                      placeholder="选择日期"
                      value-format="yyyy/MM/dd"
                    ></el-date-picker>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div v-if="enrtyForm.result == '延期'">
          <el-form-item
            label="计划入职时间："
            :rules="[
              {
                required: true,
                message: '请选择计划入职时间',
                trigger: 'change',
              },
            ]"
            prop="time"
          >
            <el-date-picker
              v-model="enrtyForm.time"
              type="date"
              placeholder="选择日期"
              style="width: 250px"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-checkbox disabled v-model="enrtyForm.addToSchedule"
              >将计划入职时间添加到日程提醒</el-checkbox
            >
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer">
        <el-button
          size="mini"
          @click="
            dialogVisibleBack = false;
            resetForm('enrtyForm');
          "
          >取 消</el-button
        >
        <el-button size="mini" type="primary" @click="saveEntry()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-drawer
      :visible.sync="dialogVisibleList"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      append-to-body
      :modal="false"
      direction="ttb"
      custom-class="drawerClass"
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-kj_list1"></i>上传人才list
      </div>
      <div class="drawerCon">
        <upResumeList
          @close="closeDrawerList"
          ref="uploadRResumeList"
          v-if="dialogVisibleList"
        ></upResumeList>
      </div>
    </el-drawer>
    <el-drawer
      :visible.sync="dialogVisibleQuick"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      append-to-body
      :modal="false"
      :before-close="cancel"
      direction="ttb"
      custom-class="drawerClass"
    >
      <div slot="title" class="drawerTitle">
        <i
          v-if="quickName == '新增客户'"
          class="drawIcon iconfont icon-kh_xq"
        ></i>
        <i
          v-if="quickName == '新增项目'"
          class="drawIcon iconfont icon-xm-xq"
        ></i>
        <i v-if="quickName == '新增合同'" class="drawIcon iconfont icon-ht"></i>
        <i
          v-if="quickName == '新增人才'"
          class="drawIcon iconfont icon-rc_bt"
        ></i>
        {{ quickName }}
      </div>
      <div class="drawerCon">
        <addorEditCustomer
          v-if="quickName == '新增客户'"
          @cancel="cancel"
          :isQuick="true"
        ></addorEditCustomer>
        <addorEditProject
          v-if="quickName == '新增项目'"
          ref="addorEditProject"
          :isQuick="true"
          @cancel="cancel"
        ></addorEditProject>
        <addorEditContract
          v-if="quickName == '新增合同'"
          ref="saveContractInfo"
          @cancel="cancel"
          @changeSaveLoading="changeSaveLoading"
          :isQuick="true"
          entry="框架"
        ></addorEditContract>
        <resumeInfo
          v-if="quickName == '新增人才'"
          ref="saveResumeInfo"
          :isQuick="true"
          :entry="entry"
        ></resumeInfo>
        <div
          class="drawerCon_footer fixedFoot"
          v-if="quickName == '新增人才' || quickName == '新增合同'"
        >
          <el-button
            type="default"
            plain
            size="mini"
            @click="cancel('点击取消按钮关闭弹框')"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="mini"
            style="margin-right: 15px"
            :loading="saveloading"
            @click="saveInfos"
            >保存</el-button
          >
        </div>
      </div>
    </el-drawer>
    <el-dialog
      :visible.sync="calendarVisible"
      :modal-append-to-body="false"
      append-to-body
      title="新增日程"
      :before-close="cancel"
      :close-on-click-modal="false"
      width="600px"
      destroy-on-close
    >
      <addorEditCalender
        ref="calenderFrom"
        v-if="calendarVisible"
        :formdata="calendardata"
        @close="caleCencel"
      ></addorEditCalender>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="caleCencel('calendardata')"
          >取 消</el-button
        >
        <el-button
          size="mini"
          :loading="saveloading"
          type="primary"
          @click="saveCalendar()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import upResume from "./views/resume/upResume";
import upResumeList from "./views/resume/upResumeList";
import addorEditCustomer from "./components/customer/addorEditCustomer";
import addorEditProject from "./components/project/addorEdit";
import addorEditCalender from "./components/calendar/from";
import addorEditContract from "./components/contract/addContract";
import resumeInfo from "./components/resumeInfo";
import * as msg from "@/libs/MsgType"; //消息提示
import { mapGetters } from "vuex";
import {
  getCurrentAccount,
  getUnReadCount,
  readAll,
  goodnewsInfo,
  allGoodNews,
  readMessage,
  addOrEditEntry,
  delayEntry,
  latestComment,
  offerStatus,
  getScheduleStatusById, //标记状态
  announcementReadCustom,
  announcementLatestCustom,
  commonApprovalCount, //当前用户待审批数量
  sysUserDefaultIndex,
} from "./api/api";
import {
  statisticsCandidateAdd, //点击新增人才
  statisticsCandidateUpload, //点击上传人才
  statisticsCandidateUploadList, //点击上传人才List
  statisticsContractAdd, //点击新增合同
  statisticsClickProjectAdd, //记录点击新增项目
  statisticsMessageCount, //点击统计消息未读总数
  statisticsMessageShow, //系统消息展示量
  statisticsFrameClick, //点击框架菜单
  statisticsClickFrameClickDuration, //记录点击框架菜单时长
} from "./api/buryingPoint";
import { ApiBaseUrl } from "@/api/http";
import Cookies from "js-cookie";
import userAvator from "./assets/currentPhoto1.png";
import avatar1 from "./assets/currentPhoto1.png";
import SockJS from "sockjs-client";
import Stomp from "stompjs";

var stompClient = null;
var _setInterval;
export default {
  name: "Home",
  components: {
    upResume,
    upResumeList,
    addorEditCustomer,
    addorEditProject,
    resumeInfo,
    addorEditCalender,
    addorEditContract,
  },
  data() {
    return {
      showQPopVis: false,
      showFeedbackVis: false,
      calendardata: {
        moduleType: "人才",
        comment: "",
        joinUserIds: [],
        scheduleType: "联系候选人",
        scheduleTime: null,
        targetId: null,
        relatePersonId: null,
      },
      quickName: "",
      calendarVisible: false,
      dialogVisibleQuick: false,
      disabled: false,
      freeBackId: null,
      planEntryTime: "",
      CommentWay: "",
      dialogVisibleBack: false,
      enrtyForm: {
        result: null,
        time: null,
        endTimeOfTrial: null,
        addToSchedule: false,
        id: 0,
        version: 0,
        projectFinished: false,
        conventionFirstMakeInvoiceDate: null,
        firstMakeInvoiceAddToSchedule: true,
      },
      pickerOptions: {
        disabledDate(time) {
          return Date.now() > time.getTime();
        },
      },
      baseUrl: ApiBaseUrl,
      activeIndex: "/Home",
      userAvator: userAvator,
      sysUserInfo: "",
      roles: [],
      dialogVisibleList: false,
      dialogVisible: false,
      saveloading: false,
      departments: [],
      avatar1: avatar1,
      enableSub: false,
      happyNewsId: null,
      isShowHpayyIcon: false,
      isDisabled: false,
      isGoldMedal: false,
      barrageIsShow: false,
      isShowStar: false,
      isOver10: null,
      isDialog: false,
      readId: "",
      ListData: [],
      longItems: [],
      shortItems: [],
      happyNewsForm: {
        candidateName: { name: null, isShow: false },
        companyName: { name: null, isShow: false },
        companySource: { name: null, isShow: false },
        entryTime: { name: null, isShow: false },
        feeStandard: null,
        id: 0,
        money: { name: 0, isShow: false },
        offerMoney: { name: 0, isShow: false },
        offerTime: { name: null, isShow: false },
        display: false,
        performanceAllocations: [],
        position: { name: null, isShow: false },
        projectResumeId: 0,
        version: 0,
      },
      notifications: {},
      noticeId: null,
      customizationMsgIsShow: false,
      customizationMsg: {
        title: "",
        content: "",
      },
      customizationMsgId: "",
      addResumePointData: {},
      entry: "框架",
      frameClickDurationData: {},
    };
  },
  computed: {
    ...mapGetters({
      count: "getCount",
      approvalcount: "getApprovalCount",
      avatar: "upDateAvatar",
      nickName: "upDateUserName",
      messageList: "getMessageList",
      mobile: "upDateMobile",
      collapsed: "upDateCollapsed",
      selfStatus: "upDateSelfStatus",
      addResume: "upDateAddResumeStatus",
    }),
  },
  directives: {
    drag(el, binding, { context }) {
      let oDiv = el; //当前元素
      //禁止选择网页上的文字
      // document.onselectstart = function () {
      //   return false;
      // };
      oDiv.onmousedown = function (event) {
        //鼠标按下，计算当前元素距离可视区的距离
        let disX = event.clientX - oDiv.offsetLeft;
        let disY = event.clientY - oDiv.offsetTop;
        let maxX = window.innerWidth - 200;
        let maxY = window.innerHeight - 124;
        context.firstTime = Date.now();
        document.onmousemove = function (e) {
          //通过事件委托，计算移动的距离
          let l = e.clientX - disX;
          let t = e.clientY - disY;
          //移动当前元素
          oDiv.style.left = l + "px";
          oDiv.style.top = t + "px";
          if (l < 0) {
            oDiv.style.left = 0 + "px";
          }
          if (t < 0) {
            oDiv.style.top = 0 + "px";
          }
          if (l > maxX) {
            oDiv.style.left = maxX + "px";
          }
          if (t > maxY) {
            oDiv.style.top = maxY + "px";
          }
        };
        document.onmouseup = function (e) {
          document.onmousemove = null;
          document.onmouseup = null;
          if (Date.now() - context.firstTime < 200) {
            context.baberrageShow();
            context.firstTime = null;
          }
        };
        //return false不加的话可能导致黏连，就是拖到一个地方时div粘在鼠标上不下来，相当于onmouseup失效
        return false;
      };
    },
  },
  mounted() {
    var userInfo = this.getStore("useInfo");
    this.roles = this.getStore("roles")
      ? JSON.parse(this.getStore("roles"))
      : [];
    this.departments = this.getStore("departments")
      ? JSON.parse(this.getStore("departments"))
      : [];
    if (userInfo) {
      userInfo = JSON.parse(userInfo);
      this.sysUserInfo = userInfo;
      this.initWebSocket(userInfo.id);
    }
  },
  watch: {
    "$route.path"(newValue, oldValue) {
      if (newValue != oldValue) {
        this.activeIndex = newValue;
      } else {
        this.activeIndex = this.getStore("activeIndex");
      }
      if (newValue == "/resume/pending" || newValue == "/resume/importRecord") {
        this.activeIndex = "/resume";
      }
      if (
        newValue == "/Pipeline/offerResume" ||
        newValue == "/Pipeline/approvalSubmit" ||
        newValue == "/Pipeline/teamPipeline"
      ) {
        this.activeIndex = "/Pipeline";
      }
    },
    dialogVisible(newValue) {
      if (newValue) {
        this.enableSub = false;
        this.noScroll(); //禁止滚动
      } else {
        this.canScroll();
      }
    },
    dialogVisibleList(newValue) {
      if (newValue) {
        this.noScroll(); //禁止滚动
      } else {
        this.canScroll();
      }
    },
    dialogVisibleQuick(newValue) {
      if (newValue) {
        this.noScroll(); //禁止滚动
      } else {
        this.canScroll();
      }
    },
    addResume(newVal, oldVal) {
      // console.log(1111)
      if (newVal) {
        this.entry = "人才";
        this.addPersonSelect();
      } else {
        this.entry = "框架";
      }
    },
  },
  created() {
    // console.log(new Date().getTime())
    this.frameClickDurationData.startTimeStamp = Math.round(
      new Date().getTime() / 1000
    );
    this.getCurrentAccount();
    this.getPageList(); //喜报
    if (this.selfStatus) {
      this.getAnnouncementLatestCustom(); //获取自定义通知
    }
    this.getUnReadCount(); //获取未读消息
    this.getApprovalCount(); //获取当前用户待审批数量
    if (this.getStore("activeIndex") && this.$route.path !== "/") {
      if (this.getStore("activeIndex") === this.$route.path) {
        this.activeIndex = this.getStore("activeIndex");
      } else {
        this.activeIndex = "/" + this.$route.path.split("/")[1];
      }
    }
    console.log(this.getStore("popSchedule"));
    let popSchedule = this.getStore("popSchedule");
    if (popSchedule == "true") {
      this.notice3();
    }
  },
  methods: {
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    caleCencel(val) {
      // this.resetForm("calendardata");
      this.calendarVisible = false;
      this.calendardata.moduleType = "人才";
      this.calendardata.comment = "";
      this.calendardata.joinUserIds = [];
      this.calendardata.scheduleType = "联系候选人";
      this.calendardata.scheduleTime = "";
      this.calendardata.targetId = null;
      this.calendardata.relatePersonId = null;
      this.calendardata.id = null;
      this.targetId1 = 0;
      this.calendarrwVisible = false;
    },
    changeResult(e) {
      if (e == "拒绝") {
        this.getLatestComment();
      } else if (e == "延期") {
        this.enrtyForm.time = "";
        this.enrtyForm.addToSchedule = true;
      } else if (e == "成功") {
        this.enrtyForm.time = this.planEntryTime;
      }
    },
    getLatestComment() {
      latestComment({ type: "Offer" }).then((res) => {
        if (res.success) {
          this.CommentWay = res.result;
          this.CommentWay.columns.forEach((item) => {
            this.$set(item, "answer1", []);
            this.$set(item, "answer", "");
          });
        }
      });
    },
    saveEntry() {
      if (!this.enrtyForm.result) {
        this.$message.error("请选择反馈结果");
        return false;
      }
      if (this.enrtyForm.result == "成功") {
        this.$refs["enrtyForm"].validate((valid) => {
          if (valid) {
            this.saveloading = true;
            addOrEditEntry({
              id: 0,
              objectContent: {
                endTimeOfTrial: this.enrtyForm.endTimeOfTrial,
                entryTime: this.enrtyForm.time,
                addToSchedule: this.enrtyForm.addToSchedule,
                projectFinished: this.enrtyForm.projectFinished,
                conventionFirstMakeInvoiceDate:
                  this.enrtyForm.conventionFirstMakeInvoiceDate,
                firstMakeInvoiceAddToSchedule:
                  this.enrtyForm.firstMakeInvoiceAddToSchedule,
              },
              projectResumeId: this.freeBackId,
              version: this.enrtyForm.version,
            }).then((res) => {
              if (res.success) {
                this.$message.success("反馈成功");
                this.dialogVisibleBack = false;
                this.resetForm("enrtyForm");
                this.closeNotify(this.noticeId);
              }
              this.saveloading = false;
            });
          } else {
            return false;
          }
        });
      } else if (this.enrtyForm.result == "拒绝") {
        this.saveloading = true;
        let commentRecord = {
          commentColumns: [],
          commentId: this.CommentWay.id,
          id: 0,
          type: this.CommentWay.type,
          version: 0,
        };
        let columns = this.CommentWay.columns;
        for (let i = 0; i < columns.length; i++) {
          if (
            columns[i].required &&
            (columns[i].answer == "" || columns[i].answer == null) &&
            columns[i].answer1.length == 0
          ) {
            this.$message.error("请将必填项填写完整");
            return false;
          }
        }
        columns.map((item) => {
          commentRecord.commentColumns.push({
            columnTitle: item.title,
            commentColumnId: item.id,
            type: item.type,
            value: item.type == "多选型" ? item.answer1.join(",") : item.answer,
          });
        });
        offerStatus({
          id: this.freeBackId,
          offerStatus: "拒绝",
          commentRecord: commentRecord,
        }).then((res) => {
          if (res.success) {
            this.$message.success("反馈成功");
            this.dialogVisibleBack = false;
            this.resetForm("enrtyForm");
            this.closeNotify(this.noticeId);
          }
          this.saveloading = false;
        });
      } else if (this.enrtyForm.result == "延期") {
        this.$refs["enrtyForm"].validate((valid) => {
          if (valid) {
            this.saveloading = true;
            delayEntry({
              addToSchedule: this.enrtyForm.addToSchedule,
              planEntryTime: this.enrtyForm.time,
              projectResumeId: this.freeBackId,
            }).then((res) => {
              if (res.success) {
                this.$message.success("反馈成功");
                this.dialogVisibleBack = false;
                this.resetForm("enrtyForm");
                this.closeNotify(this.noticeId);
              }
              this.saveloading = false;
            });
          } else {
            return false;
          }
        });
      }
    },
    // 点击展示喜报
    baberrageShow(e) {
      this.happyNewsForm = "";
      this.isDisabled = false;
      this.hasRead();
      this.barrageIsShow = true;
      this.isGoldMedal = true;
      setTimeout(() => {
        this.isShowStar = true;
      }, 2000);
    },
    // 快速浏览全部
    quickSeeAll() {
      _setInterval = setInterval(() => {
        this.isDialog = true;
        this.baberrageShow();
      }, 2000);
    },
    // 关闭喜报弹出框
    closeHappyDialog() {
      this.barrageIsShow = !this.barrageIsShow;
      this.isShowStar = !this.isShowStar;
      clearInterval(_setInterval);
    },
    //上传人选
    uponPersonSelect() {
      this.cancel();
      this.showQPopVis = false;
      this.dialogVisible = true;
      if (this.addresumeStatus) {
        this.$store.dispatch("upStatusAddResume");
      }
      statisticsCandidateUpload({ entry: "框架" }).then();
      // this.$router.push({path: '/upResume'})
    },
    // 单个消息
    goodnewsInfo() {
      goodnewsInfo({ id: this.happyNewsId }).then((res) => {
        if (res.success) {
          this.shortItems = [];
          this.longItems = [];
          this.msgDatas = res.result;
          this.happyNewsForm = res.result;
          for (let key in this.happyNewsForm) {
            if (
              key == "candidateName" ||
              key == "companyName" ||
              key == "companySource" ||
              key == "entryTime" ||
              key == "offerTime" ||
              key == "money" ||
              key == "offerMoney" ||
              key == "position"
            ) {
              this.happyNewsForm[key] = JSON.parse(this.happyNewsForm[key]);
            }
          }
          this.isOver10 = Number(this.happyNewsForm.money.name); //offer金额

          this.allocationItems = res.result.allocationItems;
          // this.allocationOther = res.result.allocationOther;
          this.allocationItems.forEach((item, i) => {
            let str = item.userName;
            let leng = 0;
            for (let j = 0; j < str.length; j++) {
              if (/[\u4e00-\u9fa5]/.test(str[j])) {
                leng += 2;
              } else {
                leng += 1;
              }
            }
            if (leng + item.roleName.length >= 17) {
              this.longItems.push(item);
            } else {
              this.shortItems.push(item);
            }
          });
          // this.allocationOther.forEach((item, i) => {
          //   let str = item.userName;
          //   let leng = 0;
          //   for (let i = 0; i < str.length; i++) {
          //     if (/[\u4e00-\u9fa5]/.test(str[i])) {
          //       leng += 2;
          //     } else {
          //       leng += 1;
          //     }
          //   }
          //   if (leng + item.roleName.length >= 17) {
          //     this.longItems.push(item);
          //   } else {
          //     this.shortItems.push(item);
          //   }
          // });
        } else {
          if (res.code == 404) {
            this.hasRead();
            this.barrageIsShow = false;
          }
        }
      });
    },
    addPersonSelect() {
      this.quickName = "新增人才";
      this.dialogVisibleQuick = true;
      this.showQPopVis = false;
      this.$store.dispatch("upDateSelfStatus", false);
      statisticsCandidateAdd({ entry: this.entry }).then();
    },
    addCustomer() {
      this.quickName = "新增客户";
      this.dialogVisibleQuick = true;
      this.showQPopVis = false;
    },
    addProject() {
      this.quickName = "新增项目";
      this.dialogVisibleQuick = true;
      this.showQPopVis = false;
      statisticsClickProjectAdd({
        entry: "系统框架",
      }).then();
    },
    addContracts() {
      this.quickName = "新增合同";
      this.dialogVisibleQuick = true;
      this.showQPopVis = false;
      statisticsContractAdd({ entry: "框架" }).then();
    },
    addCalendar() {
      this.calendarVisible = true;
      this.showQPopVis = false;
    },
    //保存新增日程
    saveCalendar() {
      this.$refs.calenderFrom.saveInfo().then((value) => {
        this.calendarVisible = false;
      });
    },
    dowmExport() {
      window.open(
        "https://www.belloai.com/download?target=casearching",
        "_blank"
      );
    },
    cancel(val) {
      if (this.quickName == "新增人才") {
        if (val == "点击取消按钮关闭弹框") {
          this.$refs.saveResumeInfo.GetstatisticsResumeAddDuration(val);
        } else {
          this.$refs.saveResumeInfo.GetstatisticsResumeAddDuration(
            "点击右上角x关闭弹框"
          );
        }
      }
      if (this.quickName == "新增项目") {
        if (val == "点击取消按钮关闭弹框") {
          this.$refs.addorEditProject.setOperationData(val);
        } else {
          this.$refs.addorEditProject.setOperationData("点击右上角x关闭弹框");
        }
      }
      this.dialogVisible = false;
      this.dialogVisibleList = false;
      this.dialogVisibleQuick = false;
      this.calendarVisible = false;
      this.caleCencel();
      this.quickName = "";
      this.$store.dispatch("upDateAddResumeStatus", false);
    },
    handleClose(val) {
      if (val == "点击取消按钮关闭弹框") {
        this.$refs.uploadRResume.setOperationData(val);
      } else {
        this.$refs.uploadRResume.setOperationData("点击右上角x关闭弹框");
      }
      this.dialogVisible = false;
    },
    changeSaveLoading(val) {
      this.saveloading = val;
    },
    closeDrawer(val) {
      this.dialogVisible = false;
    },
    closeDrawerList(val) {
      this.dialogVisibleList = false;
    },
    saveInfos() {
      if (this.quickName == "新增人才") {
        this.saveloading = true;
        this.$refs.saveResumeInfo
          .saveResume()
          .then((value) => {
            this.saveloading = false;
            this.dialogVisibleQuick = false;
            this.quickName = "";
            this.$router.push({
              path: "/resume",
              query: { t: +Date.now() },
            });
          })
          .catch(() => {
            // this.quickName =""
            this.saveloading = false;
          });
        this.$store.dispatch("upDateAddResumeStatus", false);
      } else if (this.quickName == "新增合同") {
        this.$refs.saveContractInfo.save("contract");
      } else {
        this.$refs.uploadRResume.uploadResume().then(() => {
          // this.dialogVisible = false;
        });
      }
    },
    // 上传list简历
    uploadResumeLiost() {
      this.cancel();
      this.showQPopVis = false;
      this.dialogVisibleList = true;
      if (this.addresumeStatus) {
        this.$store.dispatch("upStatusAddResume");
      }
      statisticsCandidateUploadList({ entry: "框架" }).then();
      // this.$router.push({path: '/resume/upResumeList'})
    },
    showMenu(i, status) {
      this.$refs.menuCollapsed.getElementsByClassName(
        "submenu-hook-" + i
      )[0].style.display = status ? "block" : "none";
      let liNum = this.$refs.menuCollapsed.getElementsByClassName(
        "submenu-hook-" + i
      )[0].childElementCount;
      if (liNum > 10) {
        //子集大于10个显示方式
        this.$refs.menuCollapsed.getElementsByClassName(
          "submenu-hook-" + i
        )[0].style.top = "-" + liNum * 20 + "px";
        this.$refs.menuCollapsed.getElementsByClassName(
          "submenu-hook-" + i
        )[0].style.height = "620px";
        this.$refs.menuCollapsed.getElementsByClassName(
          "submenu-hook-" + i
        )[0].style.overflow = "auto scroll";
      }
    },
    initWebSocket(userId, countNews) {
      let _this = this;
      var socket = new SockJS(ApiBaseUrl + "/myWebSocket"); //1
      stompClient = Stomp.over(socket); //2
      stompClient.connect({}, (frame) => {
        //项目相关的消息
        stompClient.subscribe(
          "/user/" + userId + "/project/message",
          (response) => {
            //喜报/财务/期报/项目
            let res = JSON.parse(response.body);
            _this.$store.dispatch("increment");
            // msg.defaultMsg(res.message);
            switch (res.type) {
              case "客户日程消息":
                _this.notice2(res);
                // msg.schMSG(
                //   `<a class="navTo" href="/customer?comId=${res.relativeId}">${res.content}<p class="time">${res.createdTime}<span class="fr">查看</span></p></a>`
                // );
                break;
              case "项目日程消息":
                _this.notice2(res);
                // msg.schMSG(
                //   `<a class="navTo" href="/project/details?id=${res.relativeId}">${res.content}<p class="time">${res.createdTime}<span class="fr">查看</span></p></a>`
                // );
                break;
              case "人才日程消息":
                _this.notice2(res);
                // msg.schMSG(
                //   `<a class="navTo" href="/resume?resumeId=${res.relativeId}">${res.content}<p class="time">${res.createdTime}<span class="fr">查看</span></p></a>`
                // );
                break;
                case "白领保温":
                _this.notice4(res);
                // msg.schMSG(
                //   `<a class="navTo" href="/resume?resumeId=${res.relativeId}">${res.content}<p class="time">${res.createdTime}<span class="fr">查看</span></p></a>`
                // );
                break;
              case "PipeLine消息":
                msg.proMSG(
                  `<a class="navTo" href="/project/details?id=${res.relativeId}&resNo=${res.secondRelativeId}">${res.content} <p class="time">${res.createdTime}<span class="fr">查看</span></p></a>`
                );
                break;
              case "入职提醒消息":
                _this.notice(res);
                break;
              case "保温期提醒消息":
                _this.notice1(res);
                break;
              case "喜报":
                this.happyNewsId = res.relativeId;
                this.isShowHpayyIcon = true;
                this.goodnewsInfo();
                this.getPageList();
                break;
              case "系统自定义通知":
                this.customizationMsgId = res.relativeId;
                this.customizationMsg.title = res.title;
                this.customizationMsg.content = res.content;
                this.customizationMsgIsShow = true;
                break;
              default:
                break;
            }
            this.getUnReadCount();
          }
        );
      });
    },
    disconnect() {
      if (stompClient != null) {
        stompClient.disconnect();
      }
    },
    //入职提醒消息
    notice(res) {
      let h = this.$createElement;
      let notify = this.$notify({
        title: "提醒",
        offset: 40,
        customClass: "warningCls",
        dangerouslyUseHTMLString: true,
        message: h("div", {}, [
          h("strong", "请您确认："),
          h("div", [
            h("span", {}, `【${res.projectName}】的【`),
            h(
              "span",
              {
                class: "tabHref",
                on: {
                  click: () => {
                    this.toResumeInfo(res.resumeId);
                  },
                },
              },
              `${res.resumeName}`
            ),
            h("span", {}, `】预计${res.planEntryTime}入职`),
          ]),
          h("div", { class: "btn" }, [
            h(
              "span",
              {
                class: " mgr10",
                on: {
                  click: () => {
                    this.closeNotify(res.id);
                  },
                },
              },
              "忽略"
            ),
            h(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  size: "mini",
                },
                on: {
                  click: () => {
                    this.freeBack(
                      res.projectResumeId,
                      res.id,
                      res.planEntryTime
                    );
                  },
                },
              },
              "反馈入职结果"
            ),
          ]),
        ]),
        showClose: false,
        duration: 0,
      });
      this.notifications[res.id] = notify;
    },
    //保温期提醒消息
    notice1(res) {
      let h = this.$createElement;
      let messageContent = h("span");
      messageContent.innerHtml = "";
      let notify = this.$notify({
        title: "提醒",
        offset: 40,
        customClass: "warningCls",
        dangerouslyUseHTMLString: true,

        message: h("div", {}, [
          h("strong", "保温期提醒："),
          h("div", [
            h(
              "span",
              {
                class: "tabHref",
                on: {
                  click: () => {
                    this.toResumeInfo(res.resumeId);
                  },
                },
              },
              `【${res.resumeName}】`
            ),
            h("span", {
              domProps: {
                innerHTML: res.content,
              },
            }),
          ]),
          h("div", { class: "btn" }, [
            h(
              "span",
              {
                class: " mgr10",
                on: {
                  click: () => {
                    this.closeNotify(res.id);
                  },
                },
              },
              "我知道了"
            ),
          ]),
        ]),
        showClose: false,
        duration: 0,
      });
      this.notifications[res.id] = notify;
    },
    //日程提醒消息
    notice2(res) {
      let h = this.$createElement;
      let notify = this.$notify({
        title: "提醒",
        offset: 40,
        customClass: "warningCls",
        dangerouslyUseHTMLString: true,
        message: h(
          "div",
          {
            style: "width: 300px;",
          },
          [
            h("div", [
              h("strong", "您有新的日程关注："),
              h(
                "span",
                {
                  class: "noticeClose",
                  on: {
                    click: () => {
                      this.closeNotify(res.id);
                    },
                  },
                },
                "关闭"
              ),
            ]),

            h("div", [
              h("span", {}, `【`),
              h(
                "span",
                {
                  class: "tabHref",
                  on: {
                    click: () => {
                      this.toInfo(res);
                    },
                  },
                },
                `${JSON.parse(res.content).targetName}`
              ),
              h(
                "span",
                {},
                `${JSON.parse(res.content).targetName ? "】" : ""}${
                  JSON.parse(res.content).relatedPerson &&
                  JSON.parse(res.content).relatedPerson !== "null"
                    ? "【"
                    : ""
                }`
              ),
              h(
                "span",
                {
                  class: "tabHref",
                  on: {
                    click: () => {
                      this.toResumeInfo(JSON.parse(res.content).relatePersonId);
                    },
                  },
                },
                `${
                  JSON.parse(res.content).relatedPerson &&
                  JSON.parse(res.content).relatedPerson !== "null"
                    ? JSON.parse(res.content).relatedPerson
                    : ""
                }`
              ),
              h(
                "span",
                {},
                `】的【${JSON.parse(res.content).scheduleType}】提醒${
                  JSON.parse(res.content).comment
                    ? "，【备注：" + JSON.parse(res.content).comment + "】"
                    : ""
                }`
              ),
            ]),
            h("div", { class: "btn" }, [
              h(
                "span",
                {
                  class: " mgr10",
                  style: "margin-right:20px;font-size:12px",
                  on: {
                    click: () => {
                      this.markerStatus(res);
                    },
                  },
                },
                "不再提醒"
              ),
              h(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                  },
                  on: {
                    click: () => {
                      this.markerStatus(res);
                    },
                  },
                },
                "完成"
              ),
            ]),
          ]
        ),
        showClose: false,
        duration: 0,
      });
      this.notifications[res.id] = notify;
    },
    //默认首页功能系统公告
    notice3() {
      let h = this.$createElement;
      let notify = this.$notify({
        title: "新功能",
        offset: 40,
        customClass: "warningCls newFunction",
        dangerouslyUseHTMLString: true,
        message: h(
          "div",
          {
            style: "width: 300px;",
          },
          [
            h("div", [
              h("span", {}, `支持设置【`),
              h(
                "span",
                {
                  style: "color:#e60012",
                },
                `默认首页`
              ),
              h("span", {}, `】啦`),
              h("p", {}, `快动动小手去自定义设置吧`),
            ]),
            h("div", { class: "btn" }, [
              h(
                "span",
                {
                  class: " mgr10",
                  style: "margin-left:20px;font-size:12px",
                  on: {
                    click: () => {
                      this.goSetAccount("close");
                    },
                  },
                },
                "不再提醒"
              ),
              h(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                  },
                  on: {
                    click: () => {
                      this.goSetAccount();
                    },
                  },
                },
                "马上设置"
              ),
            ]),
          ]
        ),
        showClose: false,
        duration: 0,
      });
      this.notificationsAccount = notify;
    },
    //白领保温
    notice4(res){
      let h = this.$createElement;
      let notify = this.$notify({
        title: "提醒",
        offset: 40,
        customClass: "warningCls",
        dangerouslyUseHTMLString: true,
        message: h("div", {}, [
          h("strong", "请您确认："),
          h("div", [
            h("span", {}, `【${res.projectName}】的【`),
            h(
              "span",
              {
                class: "tabHref",
                on: {
                  click: () => {
                    this.toResumeInfo(res.resumeId);
                  },
                },
              },
              `${res.resumeName}`
            ),
            h("span", {}, `】${res.content}`),
          ]),
          h("div", { class: "btn" }, [
            h(
              "span",
              {
                class: " mgr10",
                on: {
                  click: () => {
                    this.closeNotify(res.id);
                  },
                },
              },
              "暂缓跟进"
            ),
            h(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  size: "mini",
                },
                on: {
                  click: () => {
                    this.toResumeInfo(res.resumeId,'remark');
                  },
                },
              },
              "跟进备注"
            ),
          ]),
        ]),
        showClose: false,
        duration: 0,
      });
      this.notifications[res.id] = notify;
    },
    //去设置
    goSetAccount(close) {
      if (close) {
        sysUserDefaultIndex().then((res) => {
        });
      } else {
        this.$router.push({
          path: "/account",
        });
      }
      this.setStore("popSchedule", false);
      this.notificationsAccount.close();
      delete this.notificationsAccount;
    },
    closeNotify(id) {
      this.notifications[id].close();
      delete this.notifications[id];
    },
    toResumeInfo(id,type) {
      this.$router.push({
        path: "/resume",
        query: { resumeId: id,type:type },
      });
    },
    toInfo(res) {
      if (res.type == "客户日程消息") {
        this.$router.push({
          path: "/customer",
          query: { comId: res.relativeId },
        });
      } else if (res.type == "项目日程消息") {
        this.$router.push({
          path: "/project/details",
          query: { id: res.relativeId },
        });
      } else if (res.type == "人才日程消息") {
        this.$router.push({
          path: "/resume",
          query: { resumeId: res.relativeId },
        });
      }
    },
    //标记状态
    markerStatus(data) {
      const params = new FormData();
      params.append("id", JSON.parse(data.content).scheduleId);
      params.append("scheduleStatus", "已完成");
      getScheduleStatusById(params).then((res) => {
        if (res.success) {
          this.closeNotify(data.id);
        }
      });
    },
    freeBack(id, noticeId, planEntryTime) {
      this.dialogVisibleBack = true;
      this.noticeId = noticeId; //打开消息弹框的id
      this.freeBackId = id;
      this.planEntryTime = planEntryTime;
    },
    // 喜报列表
    getPageList() {
      allGoodNews().then((res) => {
        if (res.success) {
          this.ListData = res.result;
          if (this.readId) {
            this.goodnewsInfo();
          }
          if (res.result.length > 0) {
            this.happyNewsId = res.result[0].relativeId;
            this.relativeId = res.result[0].relativeId;
            this.readId = res.result[0].id;
          } else {
            this.isShowHpayyIcon = false;
            // setTimeout(() => {
            if (this.isDialog) {
              this.barrageIsShow = false;
              clearInterval(_setInterval);
            }
            // }, 3000)
          }
        }
      });
    },
    // 已读喜报
    hasRead() {
      return new Promise((resolve) => {
        // this.getPageList();
        readMessage({ id: this.readId }).then((res) => {
          if (res.success) {
            this.getPageList();
            this.$store.dispatch(
              "updateMessageList",
              this.messageList.map((item) => {
                if (item.label == "喜报") {
                  if (item.count > 0) {
                    // debugger
                    return {
                      ...item,
                      count: item.count - 1,
                    };
                  }
                  return item;
                }
                return item;
              })
            );
            this.$store.dispatch("decrement");
          }
          resolve();
        });
      });
    },
    decrement() {
      this.$store.dispatch("decrement");
    },
    //退出登录
    logout() {
      var _this = this;
      this.$confirm("确认退出吗?", "提示", {
        type: "warning",
      })
        .then(() => {
          Cookies.set("accessToken", "");
          this.removeStore("useInfo");
          this.removeStore("avatar");
          this.removeStore("nickName");
          this.removeStore("mobile");
          this.removeStore("u_width");
          this.removeStore("activeIndex");
          this.$store.dispatch("upDateAvatar");
          this.$store.dispatch("upDateUserName");
          this.$store.dispatch("updateCount");
          this.$store.dispatch("upDateMobile");
          _this.$router.push("/login");

          location.reload();
        })
        .catch(() => {});
    },
    collapse() {
      this.$store.dispatch("upDateCollapsed");
    },
    handleSelect(key, keyPath, routeResult) {
      // console.log(window.$globalHub.$router.options.routes[0].children);
      // console.log(routeResult,routeResult.rootMenu.$route.meta.name);
      let name = window.$globalHub.$router.options.routes[0].children.find(
        (item) => {
          return item.path == key;
        }
      );
      this.frameClickDurationData.lastMenu =
        routeResult.rootMenu.$route.meta.name;
      this.frameClickDurationData.clickMenuName = name.meta.name;
      // console.log(name.meta.name);
      let params = {
        lastMenu: routeResult.rootMenu.$route.meta.name,
        clickMenuName: "",
      };
      if (this.addresumeStatus) {
        this.$store.dispatch("upStatusAddResume");
      }
      this.dialogVisibleList = false;
      if (this.$route.path != key) {
        this.setStore("activeIndex", key);
        this.$router.push({ path: key });
        params.clickMenuName = name.meta.name;
        // console.log(routeResult,routeResult.rootMenu.$route.meta.name);
        statisticsFrameClick(params).then();
      }
    },
    getCurrentAccount() {
      //获取用户列表
      getCurrentAccount().then((res) => {
        if (res.success) {
          if (res.result.avatar) {
            let avatar = ApiBaseUrl + res.result.avatar;
            localStorage.setItem("avatar", avatar);
            this.$store.dispatch("upDateAvatar");
          } else {
            localStorage.setItem("avatar", avatar1);
            this.$store.dispatch("upDateAvatar");
          }
          this.setStore("uid", res.result.id);
          localStorage.setItem("nickName", res.result.nickName);
          localStorage.setItem("mobile", res.result.mobile);
          this.$store.dispatch("upDateUserName");
          this.$store.dispatch("upDateMobile");
          this.$nextTick(() => {
            const _width = document.getElementById("iconLength").clientWidth;
            this.setStore("u_width", _width);
            document.querySelector(".coverBorder").style.right =
              _width + 195 + "px";
            document.querySelector(".coverBorder1").style.right =
              _width + 462 + "px";
          });
        }
      });
    },
    // 获取未读
    getUnReadCount() {
      return new Promise((resolve) => {
        getUnReadCount().then((res) => {
          if (res.success) {
            this.$store.dispatch("updateCount", res.result.totalUnRead);
            this.messageOptions = res.result.typeAndCount;

            for (let i = 0; i < this.messageOptions.length; i++) {
              //this.messageOptions[i].path = this.operate[i];
              if (this.messageOptions[i].label == "喜报") {
                this.xibaoNews = this.messageOptions[i].count;
              }
            }
            this.$store.dispatch("updateMessageList", this.messageOptions);
          }
          resolve();
        });
      });
    },
    //获取当前用户待审批数量
    getApprovalCount() {
      return new Promise((resolve) => {
        commonApprovalCount().then((res) => {
          if (res.success) {
            console.log(res.result);
            this.$store.dispatch("updateApprovalCount", res.result);
          }
          resolve();
        });
      });
    },
    //全部已读
    readAll() {
      this.getstatisticsMessageCount({
        label: "全部已读",
        count: this.count,
      });
      readAll().then((res) => {
        if (res.success) {
          var totalCount = 0;
          this.$store.dispatch(
            "updateMessageList",
            this.messageList.map((item) => {
              if (item && item.label != "喜报") {
                return {
                  ...item,
                  count: 0,
                };
              } else {
                totalCount = item.count;
                return {
                  ...item,
                  count: item.count,
                };
              }
            })
          );
          this.$store.dispatch("updateCount", totalCount);
        }
      });
    },
    showQPop() {
      this.showQPopVis = !this.showQPopVis;
    },
    showFeedback() {
      this.showFeedbackVis = !this.showFeedbackVis;
    },
    //去日程
    GoCalendar() {
      this.$router.push({ path: "/calendar" });
    },
    dialogVisibleBackClose() {
      this.resetForm("enrtyForm");
      this.dialogVisibleBack = false;
    },
    // 自定义通知
    getAnnouncementLatestCustom() {
      announcementLatestCustom().then((res) => {
        if (res.success) {
          if (res.result) {
            this.customizationMsgId = res.result.id;
            this.customizationMsg.title = res.result.title;
            this.customizationMsg.content = res.result.content;
            this.customizationMsgIsShow = true;
          }
          this.$store.dispatch("upDateSelfStatus", false);
        }
      });
    },
    //关闭自定义通知
    closeCustomizationMsg() {
      announcementReadCustom({ id: this.customizationMsgId }).then((res) => {
        if (res.success) {
          this.customizationMsgIsShow = false;
        }
      });
    },
    //系统消息展示量
    setStatisticsMessageShow() {
      let params = {
        announcement: this.messageOptions[3].count,
        contract: this.messageOptions[1].count,
        finance: this.messageOptions[0].count,
        goodNews: this.messageOptions[2].count,
        other: this.messageOptions[4].count,
        sum: this.count,
      };
      statisticsMessageShow(params).then();
    },
    getstatisticsMessageCount(data) {
      statisticsMessageCount({
        type: data.label,
        unReadCount: data.count,
      }).then();
    },
    //待审批
    goPendingApproval() {
      this.$router.push({ path: "/pendingApproval" });
    },
  },
  destroyed() {
    this.disconnect();
  },
  updated() {
    // console.log(new Date().getTime())
    this.frameClickDurationData.endTimeStamp = Math.round(
      new Date().getTime() / 1000
    );
    if (this.frameClickDurationData.clickMenuName) {
      statisticsClickFrameClickDuration(this.frameClickDurationData).then(
        (res) => {
          this.frameClickDurationData.startTimeStamp = Math.round(
            new Date().getTime() / 1000
          );
          this.frameClickDurationData.clickMenuName = "";
        }
      );
    }
  },
};
</script>
<style lang="scss">
.entryClass {
  position: fixed;
  right: 20px;
  top: 20px;
  padding: 15px 20px;
  z-index: 999;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  .tx {
    color: #e60012;
    font-weight: bold;
    line-height: 1;
    font-size: 16px;
    margin-bottom: 10px;
  }
}
.btn {
  text-align: right;
  padding: 20px 0 0;
  span {
    cursor: pointer;
  }
  .freeBcka {
    background-color: #e60012;
    color: #fff;
    border-radius: 4px;
    padding: 3px 10px;

    margin-left: 8px;
  }
}
.noticeClose {
  cursor: pointer;
  position: relative;
  top: -25px;
  right: -150px;
  font-size: 12px;
  &:hover {
    color: #e60012;
  }
}
.subMenus {
  max-height: 500px;
  overflow-y: auto;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.subMenus > .el-menu > .el-menu-item:hover,
.subMenus > .el-menu > .el-submenu__title:hover {
  background-color: #fff !important;
  color: #e60012 !important;
}
.navTo {
  text-decoration: none;
  width: 290px;
  display: block;
  color: #606266;
  i {
    color: #526ecc;
    font-style: normal;
  }
  .time {
    padding-top: 20px;
    font-size: 13px;
    color: #999;
    span {
      padding: 3px 15px;
      display: inline-block;
      background-color: #e60012;
      border-radius: 4px;
      color: #fff;
      cursor: pointer;
      font-size: 12px;
    }
  }
}
.el-notification {
  padding: 15px 0px;
}
.el-scrollbar__bar.is-horizontal {
  height: 10px;
}
.el-scrollbar__thumb {
  background-color: rgba(144, 147, 153, 0.5);
}
// .home{

// .el-notification-content{
//   margin:0
// }
// }
</style>
<style scoped lang="scss">
@import "./common/css/happyNews.less";
.header {
  z-index: 999;
  position: fixed;
  height: 45px;
  line-height: 45px;
  background: #fff;
  border-bottom: 1px solid #e5e5e5;
  color: #333;
  .subHeader {
    max-width: 1640px;
    margin: 0 auto;
  }
  .icons {
    color: #999;
    z-index: 990;
    padding: 0 10px;
    position: relative;
    display: inline-block;
    vertical-align: top;

    i {
      font-size: 16px;
      &:hover {
        color: #e60012;
      }
    }
    :deep(.el-badge__content) {
      background-color: #e60012;
    }
    :deep(.el-badge__content.is-fixed) {
      top: 15px;
    }

    .itemMsg {
      position: relative;
      z-index: 666;

      .icondaohang-xiaoxi {
        padding: 5px;
        border-radius: 50%;
      }

      &:hover {
        .icondaohang-xiaoxi {
          color: #e60012;
        }
      }
    }
  }
  .logo {
    height: 44px;
    line-height: 44px;
    text-align: center;
    color: #898989;
    width: 270px;
    img {
      margin: 12px 0;
    }
  }
  .iconfont {
    color: #898989;
  }
  .userinfo {
    text-align: right;
    padding-right: 20px;
    float: right;
    // height: 44px;
    position: relative;
    // line-height: 44px;

    .userinfo-inner {
      vertical-align: top;
      cursor: pointer;
      color: #606266;
      font-size: 13px;
      display: inline-block;
      position: relative;
      margin-left: 15px;
      height: 46px;
      z-index: 990;
      .nickName {
        display: inline-block;
        @media (max-width: 1366px) {
          max-width: 70px;
        }
        max-width: 110px;
        vertical-align: center;
      }
      .el-icon-arrow-down {
        position: absolute;
        top: 15px;
      }
      .iconwangxiajiantou-dingdaohangshaixuan {
        color: #bbb;
        font-size: 12px;
        margin-left: 5px;
      }

      &:hover {
        color: #333;

        .iconwangxiajiantou-dingdaohangshaixuan {
          color: #333;
        }
      }

      img {
        width: 28px;
        height: 28px;
        border-radius: 20px;
        margin: 7px 10px 5px 0;
        float: left;
      }
    }
  }

  .userPop,
  .message {
    position: absolute;
    z-index: 9999;
    top: 46px;
    left: -180px;
    font-size: 13px;
    background-color: #fff;
    border: 1px solid #ebebeb;
    box-shadow: 0 0 10px 6px rgba(51, 51, 51, 0.1);
    display: none;

    p {
      height: 60px;
      line-height: 60px;
      margin: 0 10px;
      text-align: left;
      border-bottom: 1px solid #ddd;

      span {
        float: right;
        margin-right: 10px;
        color: #e60012;
      }
    }

    ul {
      padding-inline-start: 10px;
      padding-right: 10px;
      background-color: #fff;
    }

    li {
      height: 40px;
      line-height: 40px;
      // padding: 10px 0;
      font-size: 14px;
      text-align: left;
      color: #666;
      cursor: pointer;
    }
  }
  .message {
    width: 220px;
  }

  .el-menu-demo {
    min-width: 750px;
    height: 44px;
    line-height: 44px;
    :deep(.el-menu-item),
    :deep(.el-submenu),
    :deep(.el-submenu__title) {
      height: 44px;
      line-height: 44px;
    }
    :deep(.el-submenu__title) {
      margin: 0 10px;
      padding: 0 6px;
    }
    :deep(.is-active) .el-submenu__title {
      font-weight: bold;
      border-bottom: 3px solid #e60012;
    }
    :deep(.el-menu-item:hover) {
      background: #fff !important;
      color: #e60012 !important;
    }
    :deep(.el-submenu__title:hover) {
      background: #fff !important;
      color: #e60012 !important;
      i {
        color: #e60012 !important;
      }
    }
  }
  .logo-width {
    width: 210px;
  }

  .logo-collapse-width {
    width: 100px;
  }

  .tools {
    padding: 0 20px;
    width: 14px;
    height: 44px;
    line-height: 44px;
    color: #999999;
    cursor: pointer;

    i {
      font-size: 20px;
      color: #898989;
      vertical-align: middle;
      &:hover {
        color: #666;
      }
    }
  }
}
.iconLength {
  // min-width: 150px;
  float: right;
  /*position: fixed;*/
  top: 0;
  right: 20px;
  z-index: 990;
  min-width: 335px;
}
.content {
  position: relative;
  top: 54px;
  max-width: 1640px;
  margin: 0 auto;
  background-color: #f0f0f0;
}
.baberage-happynews {
  width: 200px;
  position: fixed;
  right: 10px;
  top: 80vh;
  z-index: 2001;
  .waitRead {
    text-align: center;
    font-size: 12px;
    width: 20px;
    height: 20px;
    background: #ff3f3f;
    border-radius: 50%;
    color: white;
    position: absolute;
    left: 23px;
    line-height: 19px;
    top: 20px;
  }
}
.baberrageWrape {
  z-index: 9999;
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}
.nameStyle {
  display: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}
.iconL {
  font-size: 14px;
}
.customizationContent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 170px 40px 30px;
  .title {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    color: #333333;
    margin-bottom: 20px;
  }
  .details {
    height: 300px;
    overflow-y: auto;
    overflow-wrap: anywhere;
    width: 100%;
  }
  ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  *:hover::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px #ededed;
    background: #ededed;
  }
  ::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #fff;
  }
}
</style>
<style lang="scss">
.warningCls {
  .el-notification__title {
    background: url("./assets/warning.png") no-repeat;
    padding-left: 30px;
    color: #333;
  }
}
.newFunction {
  .el-notification__title {
    background-position: 0px 3px;
  }
}

.quickContral {
  li {
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    padding: 0 10px;
    i {
      margin-right: 8px;
    }
    &:hover {
      background-color: #e5e5e5;
    }
  }
}
.nopadding {
  padding: 0;
}
.msgList {
  padding-top: 10px;
  li {
    height: 36px;
    line-height: 36px;
    cursor: pointer;
    padding: 0 10px;
    margin: 0 12px 10px;
    // &:first-child {
    margin-bottom: 0;
    // }
    &:hover {
      background-color: #e5e5e5;
    }
    a {
      text-decoration: none;
      color: #606266;
      display: block;
    }
  }

  span {
    float: right;
  }
  .allRead {
    border-top: 1px solid #ddd;
    line-height: 40px;
    height: 40px;
    padding: 0 20px;
    color: #666;
    margin: 0;
    text-align: center;
    cursor: pointer;
    &:hover {
      color: #e60012;
    }
  }
}
.userPop {
  width: 250px;
  font-size: 13px;
  ul {
    padding: 10px;
  }
  li {
    height: 28px;
    line-height: 28px;
    text-indent: 1em;
    .zh {
      text-indent: 0;
      vertical-align: bottom;
      display: inline-block;
      width: 150px;
    }
    .fr {
      width: 150px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
      text-indent: 0;
    }
  }
  .user-control {
    border-top: 1px solid #ededed;

    p {
      width: 49%;
      float: left;
      height: 40px;
      line-height: 40px;
      text-align: center;
      color: #666;
      border: 0;
      margin: 0;

      a {
        text-decoration: none;
      }

      span {
        float: none;
        display: inline-block;
        width: 100%;
        height: 20px;
        margin: 10px 0;
        color: #666;
        line-height: 20px;
      }
      .rline {
        border-right: 2px solid #ededed;
      }
    }

    .account {
      span {
        i {
          font-size: 16px;
          margin-right: 8px;
          color: #999;
        }

        &:hover {
          color: #e60012;

          i {
            color: #e60012;
          }
        }
      }
    }
  }
}
.el-textarea__inner {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}
</style>
