import Vue from 'vue'
import ElementUI from 'element-ui'
Vue.use(ElementUI)
export const infoMsg = (message) => {
    Vue.prototype.$notify.info({
        title: '信息',
        dangerouslyUseHTMLString: true,
        message:'<strong>'+message+'</strong>' ,
        position: 'bottom-right',
        duration:5000
      });
};
export const errMsg = (message) => {
    Vue.prototype.$notify.error({
        title: '错误',
        message: message,
        position: 'bottom-right',
        duration:5000
      });
};
export const warnMsg = (message) => {
    Vue.prototype.$notify.warning({
        title: '警告',
        message: message,
        position: 'bottom-right',
        duration:5000
      });
};
export const sucMsg = (message) => {
    Vue.prototype.$notify.success({
        title:'成功',
        message: message,
        position: 'bottom-right',
        duration:5000
      });
};
export const defaultMsg = (message) => {
    Vue.prototype.$notify({
        message: message,
        position: 'bottom-right',
        duration:5000
      });
};
export const schMSG = (message, time) => {
    Vue.prototype.$notify({
        title:'提醒',
        message: message,
        time: time,
        position: 'top-right',
        duration: 0,
        dangerouslyUseHTMLString: true
    });
};
export const proMSG = (message, time) => {
    Vue.prototype.$notify({
        title:'项目消息',
        message: message,
        time: time,
        position: 'top-right',
        duration: 5000,
        dangerouslyUseHTMLString: true
    });
};
export const goodMsg = (message, time, db) => {
    Vue.prototype.$notify({
        title:'喜报',
        message: message,
        time: time,
        position: 'bottom-right',
        duration: 5000,
        onClick: db,
        dangerouslyUseHTMLString: true
    });
};
export const rocoMsg = (message, time) => {
    Vue.prototype.$notify({
        title:'期报',
        message: message,
        time: time,
        position: 'bottom-right',
        duration: 5000,
        dangerouslyUseHTMLString: true
    });
};
export const moneyMsg = (message, time) => {
    Vue.prototype.$notify({
        title:'财务消息',
        message: message,
        time: time,
        position: 'bottom-right',
        duration: 5000,
        dangerouslyUseHTMLString: true
    });
};