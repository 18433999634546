 <template>
  <section>
    <div>
      <el-form
        @submit.native.prevent
        :model="upResumeData"
        label-width="90px"
        ref="upResumeData"
        class="uploadResume"
      >
        <p>加入项目（非必填）</p>
        <el-row>
          <el-col :span="12">
            <el-form-item label="项目：" prop="projectIds">
              <el-select
                v-model="upResumeData.projectIds"
                multiple
                filterable
                collapse-tags
                placeholder="请选择"
                style="width: 400px"
                clearable
              >
                <el-option
                  v-for="item in proList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="渠道：" prop="channel">
              <el-select
                v-model="upResumeData.channel"
                filterable
                placeholder="请选择"
                style="width: 400px"
              >
                <el-option
                  v-for="(item, index) in channelList"
                  :key="index"
                  :label="item.value"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <p>上传人才文件</p>
        <el-form-item label-width="0" prop>
          <el-upload
            ref="uploadResume"
            class="upload-demo"
            drag
            :headers="myHeaders"
            action
            multiple
            :limit="10"
            :auto-upload="false"
            :on-change="handleChange"
            :on-success="handleSuccess"
            :file-list="fileInfoList"
            :on-remove="handleRemove"
            :on-exceed="exceed"
            accept=".doc,.docx,.pdf,.jpg,.png"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或
              <span class="tabHref">点击上传</span>
            </div>
            <div
              class="el-upload__tip"
              slot="tip"
              style="
                margin-top: -5px;
                font-size: 12px;
                line-height: 1.5;
                color: #606266;
              "
            >
              文件类型支持docx，doc，pdf，jpg，png，支持上传多份文件，单个文件不超过20M
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>
    <div class="succUp" v-show="innerVisible">
      <div class="succUpBox">
        <div v-if="isloading">
          <el-progress
            :stroke-width="20"
            :percentage="percentageNum"
            :text-inside="true"
            :color="customColorMethod"
            style="margin-bottom: 20px"
          ></el-progress>
          <p v-if="successNum < totalNum">
            正在导入：<span>{{
              this.upResumeData.files[successNum].name
            }}</span>
          </p>
          <p v-else>
            正在导入：<span>{{
              this.upResumeData.files[successNum - 1].name
            }}</span>
          </p>
          <p class="tips" style="margin-top: 10px">
            请勿刷新此页，刷新会导致上传失败
          </p>
        </div>
        <div v-if="!isloading && isShowComplation">
          <el-progress
            :stroke-width="20"
            :percentage="100"
            :text-inside="true"
            status="success"
            style="margin-bottom: 20px"
          ></el-progress>
          <p>导入已完成</p>
          <p class="tips">
            请<el-button type="text" size="mini" @click="toRecode"
              >点击</el-button
            >查看导入结果
          </p>
          <p class="tips">
            请<el-button type="text" size="mini" @click="toContinue"
              >点击</el-button
            >返回继续导入
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Cookies from "js-cookie";
import * as data from "./../../libs/data.json";
import {
  uploadResumes,
  getProjectsInProgress,
  analysisResumeNum,
} from "../../api/api";
import {
  statisticsClickFunctionEscape, //记录功能或页面跳出
} from "../../api/buryingPoint";
export default {
  data() {
    return {
      channelList: data.selectChannelList,
      myHeaders: {
        Authorization: Cookies.get("accessToken"),
      },
      isloading: false,
      isShowComplation: false,
      fileInfoList: [],
      fullscreenLoading: false,
      innerVisible: false,
      upResumeData: {
        channel: "上传",
        files: [],
        projectIds: [],
      },
      proList: [],
      successNum: 0,
      totalNum: 0,
      myInterval: null,
      guId: null,
      source: null,
      percentageNum: 0,
      isOperation: false, //是否进行有效操作
    };
  },
  watch: {
    "upResumeData.files": {
      handler(v, ov) {
        this.$emit("input", v.length > 0);
      },
      deep: true,
    },
  },
  methods: {
    customColorMethod(percentage) {
      if (percentage < 50) {
        return "#f56c6c";
      } else if (percentage < 100) {
        return "#e6a23c";
      } else {
        return "#67c23a";
      }
    },
    handleSuccess: function (res, file, fileList) {},
    handleChange(file, fileList) {
      const fileType = file.name.split(".")[file.name.split(".").length - 1];
      // .doc,docx,pdf,txt, html
      if (fileList.length >= 10) {
        this.$message.error("最多只能上传10个文件！");
        return;
      }
      const isLtM = file.size / 1024 / 1024 < 20;
      if (
        fileType != "doc" &&
        fileType != "docx" &&
        fileType != "pdf" &&
        fileType != "png" &&
        fileType != "jpg"
      ) {
        this.$message.error("上传文件格式不符合规定");
        this.fileInfoList = [];
        return false;
      }
      if (!isLtM) {
        this.$message.error("上传文件大小不能超过 20MB!");
        this.fileInfoList = [];
        return false;
      }

      this.upResumeData.files = [];
      fileList.forEach((i) => {
        this.upResumeData.files.push(i.raw);
      });
    },
    handleRemove(file, fileList) {
      this.upResumeData.files = [];
      fileList.forEach((i) => {
        this.upResumeData.files.push(i.raw);
      });
    },
    toRecode() {
      this.$router.push({
        path: "/resume/importRecord",
        query: { t: +Date.now() },
      });
      this.$emit("closeDia", false);
      this.innerVisible = false;
    },
    toContinue() {
      this.innerVisible = false;
      this.isShowComplation = false;
      this.successNum = 0;
      this.totalNum = 0;
      this.upResumeData.files = [];
      this.upResumeData.projectIds = [];
      this.upResumeData.channel = "上传";
      this.fileInfoList = [];
      this.myInterval = null;
      this.source = null;
      this.guId = null;
    },
    loadProList(row) {
      getProjectsInProgress().then((res) => {
        if (res.success) {
          this.proList = res.result;
        }
      });
    },
    exceed() {
      this.$message.error("最多只能上传10个文件！");
    },
    uploadResume() {
      this.isOperation = true;
      if (this.upResumeData.files.length == 0) {
        this.$message.error("请先上传人才文件");
        return false;
      }
      this.totalNum = this.upResumeData.files.length;
      // 上传loading
      this.innerVisible = true;
      this.isloading = true;
      let body = new FormData();
      this.upResumeData.files.forEach((i) => {
        body.append("files", i);
      });
      if (this.upResumeData.projectIds) {
        body.append("projectIds", this.upResumeData.projectIds);
      }
      this.guId = `${+new Date()}${Math.random()}`;
      body.append("guId", this.guId);
      body.append("channel", this.upResumeData.channel);
      this.getAnalysisResumeGuId();
      return new Promise((resolve) => {
        uploadResumes(body).then((res) => {
          this.fullscreenLoading = false;
          this.isShowComplation = true;
          let that = this;
          setTimeout(() => {
            clearInterval(that.myInterval);
            that.cancelRequest();
            if (res.success) {
              that.isloading = false;
              that.innerVisible = true;
              resolve();
            } else {
              that.isloading = false;
              that.innerVisible = false;
            }
          }, 500);
        });
      });
    },
    getAnalysisResumeGuId() {
      let that = this;
      that.myInterval = setInterval(() => {
        if (this.percentageNum == 99) {
          clearInterval(this.myInterval);
        } else {
          this.percentageNum++;
        }
        analysisResumeNum({ guId: that.guId }, this).then((res) => {
          if (res.success) {
            that.successNum = Number(res.result);
            if (that.totalNum == that.successNum) {
              clearInterval(that.myInterval);
              that.cancelRequest();
            }
          }
        });
      }, 100);
    },
    cancelRequest() {
      if (typeof this.source === "function") {
        this.source("终止请求"); //取消请求
      }
    },
    setOperationData(val) {
      this.nextStep = val;
    },
  },
  created() {
    this.startTimeStamp = Math.round(new Date().getTime() / 1000);
    this.loadProList();
  },
  destroyed() {
    if (!this.isOperation) {
      statisticsClickFunctionEscape({
        endTimeStamp: Math.round(new Date().getTime() / 1000),
        entry: "框架",
        presentStep: "上传人才",
        startTimeStamp: this.startTimeStamp,
        nextStep: this.nextStep,
      }).then();
    }
  },
};
</script>
<style lang="less" scoped>
.uploadResume {
  border: 1px solid #e5e5e5;
  p {
    color: #333;
    height: 36px;
    line-height: 36px;
    border-bottom: 1px solid #ededed;
    margin: 0;
    background-color: #f0f0f0;
    font-size: 14px;
    padding: 0 15px;
  }
  .el-form-item {
    padding: 15px 0;
    margin-bottom: 0;
  }
}
.succUp {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  z-index: 9998;
  background-color: rgba(255, 255, 255, 0.8);
  .succUpBox {
    position: fixed;
    z-index: 9999;
    left: calc(50% - 125px);
    top: calc(50% - 100px);
    width: 250px;
    text-align: center;
    color: #666;
    i {
      margin-bottom: 10px;
    }
    p {
      color: #666;
      font-size: 18px;
      margin: 0;
    }
    .tips {
      font-size: 14px;
      .el-button--text {
        font-size: 14px;
        color: #31a6fe;
        text-decoration: underline;
      }
    }
  }
}
</style>
<style lang="scss">
.uploadResume {
  .iconshangchuan {
    display: inline-block;
    font-size: 60px;
    color: #c2c2c2;
    margin-top: 40px;
  }
  .el-upload__tip {
    padding-left: 15px;
    padding-bottom: 5px;
  }
  .el-upload__text {
    line-height: 1;
    color: #666;
  }
  .el-upload {
    margin: 5px 15px;
    width: calc(100% - 30px);
    .el-upload-dragger {
      height: 145px;
      width: 100%;
      border-radius: 0;
    }
  }
}
</style>
