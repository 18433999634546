<template>
  <section>
    <div>
      <p class="DialogTips">
        温馨提示：上传list简历可直接把通讯录中的信息逐条转化为简历入库，为了提高上传效率，请手动删除上传表格中非简历字段信息
      </p>
      <el-form
        @submit.native.prevent
        :model="upResumeListData"
        label-width="60px"
        ref="upResumeListData"
        class="uploadResumeList"
      >
        <el-form-item label-width="0" prop>
          <el-upload
            ref="uploadResume"
            class="upload-demo"
            drag
            :headers="myHeaders"
            action
            :auto-upload="false"
            :on-change="handleChange"
            :on-success="handleSuccess"
            :file-list="fileInfoList"
            :on-remove="handleRemove"
            :limit="1"
            :on-exceed="onExceed"
            accept=".xlsx, .xls"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或
              <span class="tabHref">点击上传</span>
            </div>
            <div
              style="
                margin-top: -5px;
                font-size: 12px;
                line-height: 1.5;
                color: #606266;
              "
              slot="tip"
            >
              仅支持xlsx，xls的文件，单次仅支持上传1份文件，大小不超过1M
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>
    <div class="drawerCon_footer fixedFoot">
      <el-button size="mini" @click="closeDr">取消</el-button>
      <el-button
        :disabled="upResumeListData.file.length == 0"
        size="mini"
        type="primary"
        style="margin-right: 15px"
        @click="uploadResumeList"
        >导入</el-button
      >
    </div>

    <el-dialog
      :visible.sync="tipDialog"
      :modal-append-to-body="false"
      append-to-body
      title="提示"
      :close-on-click-modal="false"
      width="600px"
    >
      <p>
        正在解析中，需要几分钟的时间，稍后可在【导入记录】
        中查看，关闭此对话框可上传下一份list简历文件
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="checkexport">查看导入记录</el-button>
        <el-button size="mini" @click="continueExport" type="primary"
          >继续导入List</el-button
        >
      </span>
    </el-dialog>
  </section>
</template>

<script>
import Cookies from "js-cookie";
import { uploadListResumes } from "../../api/api";
import {
  statisticsClickFunctionEscape, //记录功能或页面跳出
} from "../../api/buryingPoint";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      upResumeListData: {
        file: [],
      },
      tipDialog: false,
      myHeaders: {
        Authorization: Cookies.get("accessToken"),
      },
      fileInfoList: [],
      nextStep: null,
      isOperation: false, //是否进行有效操作
    };
  },
  created() {
    this.startTimeStamp = Math.round(new Date().getTime() / 1000);
  },
  mounted() {},
  activited() {},
  update() {},
  beforeRouteUpdate() {},
  destroyed() {
    if (!this.isOperation) {
      statisticsClickFunctionEscape({
        endTimeStamp: Math.round(new Date().getTime() / 1000),
        entry: "框架",
        presentStep: "上传人才list",
        startTimeStamp: this.startTimeStamp,
        nextStep: this.nextStep ? this.nextStep : "点击右上角x关闭弹框",
      }).then();
    }
  },
  methods: {
    handleChange(file, fileList) {
      const isLtM = file.size / 1024 / 1024 < 1;
      let fileType = file.name.split(".")[file.name.split(".").length - 1];
      if (fileType != "xlsx" && fileType != "xls") {
        this.$message.error("上传的文件必须是.xlsx或.xls格式");
        this.fileInfoList = [];
        return false;
      }
      if (!isLtM) {
        this.$message.error("上传文件大小不能超过 1MB!");
        this.fileInfoList = [];
        return false;
      }
      this.upResumeListData.file = [];
      fileList.forEach((i) => {
        this.upResumeListData.file.push(i.raw);
      });
    },
    handleSuccess: function (res, file, fileList) {},
    closeDr() {
      this.nextStep = "点击取消按钮关闭弹框";
      this.$emit("close", false);
    },
    checkexport() {
      this.tipDialog = false;
      this.$emit("close", false);
      this.$router.push({
        path: "/resume/importRecord",
      });
    },
    continueExport() {
      this.tipDialog = false;
      this.upResumeListData.file = [];
      this.fileInfoList = [];
    },
    handleRemove(file, fileList) {
      this.upResumeListData.file = [];
      fileList.forEach((i) => {
        this.upResumeListData.file.push(i.raw);
      });
    },
    onExceed() {
      this.$message.error("一次仅可上传单个文件");
    },
    // 导入
    uploadResumeList() {
      this.isOperation = true;
      if (this.upResumeListData.file.length == 0) {
        this.$message.error("请先上传文件");
        return false;
      }
      let body = new FormData();
      this.upResumeListData.file.forEach((i) => {
        body.append("file", i);
      });
      this.tipDialog = true;
      return new Promise((resolve) => {
        uploadListResumes(body).then((res) => {});
      });
    },
  },
  filter: {},
  computed: {},
  watch: {},
};
</script>

<style lang="less">
.uploadResumeList {
  .iconshangchuan {
    display: inline-block;
    font-size: 60px;
    color: #c2c2c2;
    margin-top: 40px;
  }
  .el-upload__text {
    line-height: 1;
    color: #666;
  }
  .el-upload {
    width: 100%;
    .el-upload-dragger {
      height: 145px;
      width: 100%;
      border-radius: 0;
    }
  }
}
.succUp {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.8);
  .succUpBox {
    position: fixed;
    z-index: 9999;
    left: calc(50% - 125px);
    top: calc(50% - 100px);
    width: 250px;
    text-align: center;
    color: #666;
    i {
      margin-bottom: 10px;
    }
    p {
      color: #666;
      font-size: 18px;
      margin: 0;
    }
    .tips {
      font-size: 14px;
      .el-button--text {
        font-size: 14px;
        color: #31a6fe;
        text-decoration: underline;
      }
    }
  }
}
</style>
